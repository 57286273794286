<template>
    <div>
        <div v-if="!loading">

            <!--- MAIN CONTENT -->
            <div class="main-content">
                <fade-transition :duration="200" origin="center top" mode="out-in">
                    <b-row> 
                        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

                            <ValidationObserver ref="form">
                                <form @submit.prevent="onSubmit">
                                    <content-step-1 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" v-if="step === 1"/>
                                    <content-step-2 :itemData.sync="item_data" :loading="loading_next_step" v-if="step === 2" />
                                </form>
                            </ValidationObserver>

                            <div v-if="loading_partial === false && step !== 2" class="mt-4 mb-3" >
                                <app-button @click="saveStep(0)" type="cancel" :disabled="loading">{{trans('save',255)}}</app-button>
                                <app-button @click="nextStep()" :disabled="loading == true" :loading="loading">{{trans('continue',255)}}</app-button>
                            </div>

                        </b-col>
                    </b-row>
                </fade-transition>
            </div>
        </div>
        
        <!-- LOADER HAPPY NURSE -->
        <b-row v-if="loading">
            <app-loader />
        </b-row>

    </div>
</template>
<script>

import { FadeTransition } from 'vue2-transitions';
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppLoader from '@core/components/AppLoader.vue';
import ContentStep1 from './Step1/PeopleRisicoFormStep1.vue';
import ContentStep2 from './Step2/PeopleRisicoFormStep2.vue';

export default {
    components: {
        ValidationObserver,
        FadeTransition,
        AppLoader,
        ContentStep1,
        ContentStep2,
    },

    created(){
        this.getData();
    },
    
    data() {
        return {
            step: 1,

            item_data: {
                step: 0,
                applicant_type: 1,
                sex: null
            },

            loading: false,
            loading_next_step: false,
            loading_partial: false,
            show_success_alert: false,
            show_unsuccess_alert: false,
        };
    },
    
    
    methods: {
       getData(){

           this.loading = true;

           axios
                .get("people/risico-form/getData/" + this.$route.params.token)
                .then((res) => {  
                    
                    this.item_data = res.data;
                    this.step = res.data.step;                  
                    
                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });  
       },

       saveStep(go_forward){
            if(go_forward == 1){
                this.loading_next_step = true;
            }

            this.loading = true;

            let form_data = new FormData();

            if(this.step == 1){//zapisanie kroku 1

                form_data.append("go_forward", go_forward);
                form_data.append("item_data", JSON.stringify(this.item_data));

                axios
                    .post("people/risico-form/saveStepOne/" + this.$route.params.token,  form_data)
                    .then((res) => {
                        
                        this.item_data = res.data;
                        this.step = res.data.step;
                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                        this.loading_next_step = false;
                        this.show_unsuccess_alert = !this.show_unsuccess_alert;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loading_next_step = false;

                        if(go_forward == 0){
                            this.show_success_alert = !this.show_success_alert;
                        }

                        document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                        
                    });

            }

            
        },

        nextStep(){
            
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.form.errors)
                            .map(([key, value]) => ({
                                key,
                                value,
                            }))
                            .filter((error) => {
                                return !error || !error.value || error.value.length > 0;
                            });
                            
                        if (errors && errors.length > 0) {

                            if(this.$refs.form.refs[errors[0]["key"]] != null){
                                this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                });
                            } 
                        }
                    }, 100);

                    return false;
                }
                
                this.saveStep(1);
            });

        },
    },

    

};
</script>

<style scoped>

</style>
